/* src/components/ProductCard.css */

.tienda {
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0' gradientTransform='rotate(145,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23089704'/%3E%3Cstop offset='1' stop-color='%23CF6'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1' gradientTransform='rotate(15,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%2314FF3D'/%3E%3Cstop offset='1' stop-color='%23EFFF06'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='7.26'%3E%3Cpath transform='translate(-1.4 -0.8) rotate(-0.8 1409 581) scale(0.994)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='2.4200000000000004' transform='translate(-5 2) rotate(-0.4 800 450) scale(0.999)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(-1.4 3) rotate(-5 401 736) scale(0.999)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='2.2'%3E%3Cpath transform='translate(12 1.2) rotate(-0.3 150 345) scale(1.002)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='4.840000000000001' transform='translate(3 -6) rotate(-7.2 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='translate(-0.8 -0.8) rotate(-1.2 1400 132) scale(0.99)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
}
#tienda3 {
    background-color: #ffffff;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0' gradientTransform='rotate(202,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%233B32FF'/%3E%3Cstop offset='1' stop-color='%235EFFFA'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1' gradientTransform='rotate(164,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%2360B5FF'/%3E%3Cstop offset='1' stop-color='%239D43FF'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='4.949999999999999'%3E%3Cpath transform='translate(-15.05 1.1999999999999997) rotate(0.1499999999999999 1409 581) scale(0.9949159999999999)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='1.6500000000000001' transform='translate(-17 11) rotate(1.3 800 450) scale(1.001992)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(3.5 -15) rotate(11 401 736) scale(1.001992)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='1.5'%3E%3Cpath transform='translate(66 -0.3999999999999999) rotate(0.09999999999999998 150 345) scale(0.996972)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='3.3000000000000003' transform='translate(-22 -29.5) rotate(10.799999999999999 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='translate(-43.6 12.4) rotate(1.8000000000000003 1400 132) scale(0.98)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
background-attachment: fixed;
background-size: cover;
}
#tienda4 {
    background-color: #ffffff;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0' gradientTransform='rotate(213,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23FF0F0C'/%3E%3Cstop offset='1' stop-color='%23FFC280'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1' gradientTransform='rotate(304,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23F00'/%3E%3Cstop offset='1' stop-color='%23FFCEA6'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='4.949999999999999'%3E%3Cpath transform='translate(-7.7 1.2000000000000002) rotate(0.6 1409 581) scale(1.000988)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='1.6500000000000001' transform='translate(-6.5 5) rotate(1 800 450) scale(1.002)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(2.9000000000000004 -10.5) rotate(9.5 401 736) scale(1.002)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='1.5'%3E%3Cpath transform='translate(30 -1) rotate(0.25 150 345) scale(0.9969959999999999)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='3.3000000000000003' transform='translate(-14.5 -13) rotate(10.8 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='translate(-24.4 7.6) rotate(1.7999999999999998 1400 132) scale(0.995)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
background-attachment: fixed;
background-size: cover;
}

.product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    max-width: 1400px;

}



.product-card {
    flex: 1;

    max-width: 300px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    text-align: center;
    transition: transform 0.3s ease;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.32);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border: 1px solid rgba(255, 255, 255, 0.22);



    img {
        max-width: 100%;
        border-radius: 8px;
        z-index: 15;
    }

    h3 {
        font-size: 1.2rem;
        margin: 8px 0;
        color: #333;
        z-index: 15;
    }

    p {
        font-size: 10px;
        color: #777;
        z-index: 15;
    }


}

.product-card p {
    font-size: 10px !important;
}

.product-card h4 {
    font-size: 14px;
    color: #777;
}

.product-card h3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* Número de líneas que deseas mostrar */
    overflow: hidden;
    margin-top: 6px;
    
}

.product-card #description {
    margin-bottom: 10px;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: 600;

}

.pagination button {
    font-size: 14px;
    font-weight: 600;
    color: #333;
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Fondo oscuro semitransparente */
    z-index: 9;
    /* Asegura que esté por encima de todo el contenido */
}

.product-card .chakra-button {

    bottom: 0;
    margin-top: 10px;
    margin-bottom: 0;
    width: 100%;
}

.chakra-portal {
    z-index: 10000000;
    position: relative;
}

.disable-scroll {
    overflow: hidden;
}

.marcas {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 6rem;
    margin-bottom: 20px;
    margin-top: 10px;
}

@media screen and (max-width: 568px) {
    .marcas {
        flex-direction: column;
        /* Cambia la dirección de los elementos a columnas */
        align-items: center;
        text-align: center;
        /* Alinea los elementos a la izquierda */
        gap: 1px;
        margin-top: -60px;
        /* Espacio entre elementos de la lista en dispositivos pequeños */
    }

    .marcas img {
        /* Elimina el espacio adicional a la derecha en dispositivos pequeños */
        width: 100%;
        /* Ocupa el ancho completo en dispositivos pequeños */
    }

    .marcas button {
        transform: scale(0.8) !important;
        gap: 10px;
    }

    .muebles {
        margin-top: 60px;
    }
}

.mint {
    aspect-ratio: 1/1;
    transform: scale(0.7);
    padding: 20px;
    border: 2px solid rgb(0, 0, 0);
    border-radius: 50%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    background: url('https://mintbymichelle.com/cdn/shop/files/Mint_By_Michelle_Logo_Aug_2020_keyline_transparent_BG_c3d36380-76f0-4578-85ce-1a9cde840a5c_200x.png?v=1672719358') center center/cover;
    transition: 0.5s ease-in-out;
}

.mint:hover {
    transform: scale(0.9);
    cursor: pointer;
    box-shadow: 0 4px 30px rgba(49, 233, 12, 0.475);
    /* Estilos adicionales cuando está en hover */
}

.cadence {
    aspect-ratio: 2/1;
    transform: scale(0.8);
    padding: 30px;
    border: 2px solid rgb(0, 0, 0);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    background: url('https://www.artesaniasmontejo.com/c/841-pos_category/cadence.jpg') center center/cover;
    transition: 0.5s ease-in-out;

}

.cadence:hover {
    transform: scale(0.9);
    cursor: pointer;
    box-shadow: 0 4px 30px rgba(12, 108, 233, 0.475);
    /* Estilos adicionales cuando está en hover */
}

.muebles {
    aspect-ratio: 2.8/1.1;
    transform: scale(0.8);
    padding: 30px;
    border: 2px solid rgb(0, 0, 0);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    background: url('https://i.ibb.co/P9jv51q/Carmen-Lands-Logo-Ornamentos.png') center center/cover;
    transition: 0.5s ease-in-out;

}

.muebles:hover {
    transform: scale(1);
    cursor: pointer;
    box-shadow: 0 4px 30px rgba(233, 27, 12, 0.475);
    /* Estilos adicionales cuando está en hover */
}

#mint-text {
    font-size: 20px;
    font-weight: 600;
    color: #2c8b64;

}

#mint-text:hover {
    text-decoration: underline;
}

#cadence-text {
    font-size: 20px;
    font-weight: 600;
    color: rgba(12, 108, 233, 1);

}

#cadence-text:hover {
    text-decoration: underline;
}

#muebles-text {
    font-size: 20px;
    font-weight: 600;
    color: #8b2c2c;

}

#muebles-text:hover {
    text-decoration: underline;
}

.pp {
 
    background-color: #2c8b64;
}
.animated-text {
    position: relative;
    animation-duration: 10s; /* Ajusta esto para que coincida con la duración del useEffect */
    font-size: 20px;
    font-weight: 300;
    color: #ffffff;
    padding: 8px;
    
  }
  
  .appear {
    animation-name: appearAnimation;
  }

  
  @keyframes appearAnimation {
    from {
      left: 100%; /* Inicia fuera del borde izquierdo */
    }
    to {
      left: 0%;
    }
  }
  
.cadence-card {
    flex: 1;
    max-width: 300px;
    margin: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    text-align: center;
    transition: transform 0.3s ease;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.32);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border: 1px solid rgba(255, 255, 255, 0.22);

}

.cadence-card:hover {
    transform: scale(1.15);
}





/* Establece un máximo y un ancho fijo para las imágenes en el carrusel */
.product-card .carousel-root {
    max-height: 200px;

    /* Ajusta la altura máxima según tus necesidades */
}

.product-card .carousel-2 {
    justify-content: center;
    align-items: center;
    width: 30vh;
    height: 20vh;
    margin-bottom: 10px;
}



.product-card .carousel-2-mobile {
    justify-content: center;
    align-items: center;
    width: 33vh;
    height: 25vh;
    margin-bottom: 10px;
}



.product-card .carousel img {
    width: 200px;
    height: 20vh;
    object-fit: cover;
}

#tienda4 .product-list .product-card  img {
    
    object-fit: cover;
}


.product-card .carousel-2 img {
    object-fit: cover;
}

@media screen and (min-width: 768px) {
    .product-card:hover {
        transform: scale(1.65);
        z-index: 5;
        /* Estilos adicionales cuando está en hover */
    }

}

.chakra-modal__body {
    margin-top: -3rem;
}

.envios span {

    color: rgb(255, 212, 69);
    padding: 6px;
    cursor: pointer;

}

.envios span svg {
    margin-top: -2px;
    margin-left: -5px;
}


#buttonCompra {
    width: 70%;
    margin: 10px;
}

.close-cart-button {
    padding: 10px;
    border: 1px solid whitesmoke;
}



.filter {
    display: flex;
    justify-content: center;
    /* Centro horizontalmente */
    align-items: center;
    gap: 30px;
    margin-top: 20px;
    /* Centro verticalmente */
}

.filter-item {
    display: flex;
    align-content: center;
    gap: 10px;
    padding-right: 7.5rem;
    /* Espacio entre elementos de la lista */
}

#checkbox {
    width: 50px !important;
}

@media screen and (max-width: 768px) {
    .filter {
        flex-direction: column;
        /* Cambia la dirección de los elementos a columnas */
        align-items: center;
        text-align: center;
        /* Alinea los elementos a la izquierda */
        gap: 10px;
        /* Espacio entre elementos de la lista en dispositivos pequeños */
    }

    .filter-item {
        /* Elimina el espacio adicional a la derecha en dispositivos pequeños */
        width: 100%;
        /* Ocupa el ancho completo en dispositivos pequeños */
    }
}



.cart-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
}

.cart-container li {
    margin-top: 1rem;
    margin-right: 2rem;
}

.cart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}





@media screen and (max-width: 768px) {
    .cart-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        margin-left: -20px;
    }
}

.compras {
    flex: 1;
    margin-right: 20px;
    /* Margen a la derecha del formulario */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 1rem;
}

.compras li {
    margin-left: 4rem;
}

.container-compra {
    display: flex;
    margin: 20px;
    /* Margen exterior alrededor de todo el contenedor */
}

.container-compra h2 {
    font-size: 25px;
    font-weight: 600;
}



.formulario-compra {
    flex: 1;
    margin-right: 50px;
    /* Margen a la derecha del formulario */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: -1.5rem;
}



.imagen {
    position: relative;
    /* Necesario para que el texto se posicione correctamente */
    flex: 1;
    display: flex;
    align-items: center;
}

.imagen img {
    width: 100%;
    align-items: end;
    height: auto;
    object-fit: contain;
}

.texto {
    position: absolute;
    /* Lo posicionamos encima de la imagen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(44, 139, 100, 0.7);
    /* Fondo semitransparente */
    color: white;
    /* Color del texto */
    opacity: 0;
    /* Inicialmente oculto */
    transition: opacity 0.3s;
    /* Agregamos una transición suave de opacidad */
    font-size: 25px;
    font-weight: 400;
}



.imagen:hover .texto {
    opacity: 1;
    /* Mostrar el texto al hacer hover */
}

.imagen:hover {
    border: 1px solid white;
}

.campo {
    margin-bottom: 10px;
    /* Margen entre los campos del formulario */
}

.campo input {
    background-color: #fff;
    color: #333;
    margin-left: 1.5rem;
    margin-top: 0.5rem;
}

.campo ::-webkit-input-placeholder {
    color: #ccc;
    font-style: italic;
}

.campo-incompleto {
    border: 2px solid rgb(233, 22, 22);
    /* Establece un borde rojo para resaltar el campo */
}

.remove-button {
    width: 8rem;
    margin-left: 2rem;
}

.remove-button2 {
    width: 40%;
    margin-left: 2rem;
}

.remove-button3 {
    width: 15rem;
    margin-right: 2rem;
}

.menu h1 {
    font-size: 35px;
    margin-bottom: 10px;
    font-weight: 700;
}

.menu h2 {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 600;
}

.menu h3 {
    font-size: 15px;
    margin-bottom: 18px;
    font-weight: 300;
}

.waves {
    position: relative;
    overflow: hidden;
}

.waves:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    animation: waves 1s linear;
}

@keyframes waves {
    to {
        width: 200%;
        height: 200%;
        opacity: 0;
    }
}

#materiales {
    font-size: larger;
    font-weight: 500;
    text-transform: uppercase;
}



/* Puedes ajustar el espaciado, márgenes y estilos según tus necesidades */

@media (max-width: 668px) {

    .container-compra {
        transform: scale(0.9);
        margin-left: -1rem;
        margin-bottom: -4rem;

    }

    .cart-container {
        transform: scale(0.85);
        margin-bottom: -4.2rem;
    }

    #buttonCompra {
        transform: scale(1.25);
        margin-left: 0rem;
    }

    .cart {
        margin-bottom: 3rem;
        margin-top: -3.8rem;
    }

    .cart h2 {
        padding-top: 15px;
        margin-left: 35px;
    }

    .remove-button {
        width: 2.5rem;
        margin-left: 1rem;
    }

    .remove-button span svg {
        margin-top: -2px;
        margin-left: -8px;
    }

    



    .bn632-hover {
        margin-bottom: 1rem;
        transform: scale(1.3);
    }

    .remove-button3 {
        width: 13rem;
        margin-left: 2rem;

    }

    .cart-container li {
        margin-top: 1rem;
        margin-right: 0rem;
    }

    .item-name {
        display: inline-block;
        max-width: 200px; /* Ajusta según sea necesario */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; /* Agrega puntos suspensivos (...) al final del texto truncado */
        margin-bottom: -6px;
      }




}

@media (max-width: 840px) {
    .container-compra {
        flex-direction: column;

    }

    #buttonCompra {
        margin: 10px;
    }

    .container-compra h2 {
        font-size: 26px;
        font-weight: 600;
        margin-top: 20px;
    }


}

@media (max-width: 450px) {

    .container-compra {
        transform: scale(0.9);
        margin-left: -1rem;
        margin-bottom: -4rem;

    }

    .formulario-compra h2 {
        margin-left: 1rem;
    }

    #buttonCompra {
        transform: scale(1.25);
        margin-left: 0rem;
    }

    .bn632-hover {
        margin-bottom: 1rem;
        transform: scale(1.3);
    }

    .remove-button3 {
        width: 13rem;
        margin-left: 2rem;

    }

    .menu input {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .menu h1 {
        font-size: 35px;
        margin-bottom: 10px;
        font-weight: 700;
    }

    .menu h3 {
        font-size: 15px;
        margin-bottom: 18px;
        font-weight: 300;
        padding: 8px;
    }



    .imagen {
        display: none;
    }

    .compras ul button .eliminar {
        display: none;
    }

    .cart ul button .eliminar {
        display: none;
    }

    .compras ul {
        font-size: 17px;

    }

    .compras ul button {
        width: 2rem;

    }

    .compras ul button span {

        margin-right: 0.5rem;

    }

    .item-name {
        display: inline-block;
        max-width: 210px; /* Ajusta según sea necesario */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; /* Agrega puntos suspensivos (...) al final del texto truncado */
        margin-bottom: -6px;
      }

    .css-qlig70 {
    font-size: 11px;
    }

}
.compras ul button .eliminar {
    display: none;
}
.compras ul button {
    width: 1rem;

}
.compras ul button span {

    margin-right: 0.5rem;

}


@media (min-width: 450px) and (max-width: 1268px) {

    .imagen {
        display: none;
    }

    .formulario-compra h2 {
        margin-left: 1rem;
    }

    .bn632-hover {
        margin-bottom: 1rem;
    }

    .filter-button {
        margin-top: 1rem;
    }

    .compras ul button .eliminar {
        display: none;
    }

    .compras ul {
        font-size: 14px;

    }

    .compras ul button {
        width: 1rem;

    }

    .compras ul button span {

        margin-right: 0.5rem;

    }

    .menu h1 {
        font-size: 35px;
        margin-top: 15px;
        margin-bottom: 10px;
        font-weight: 700;
    }

    .menu h3 {
        font-size: 15px;
        margin-bottom: 18px;
        font-weight: 300;
        margin: 15px;
    }
    



}

@media (max-width:1920px) {
    .item-name {
        display: inline-block;
        max-width: 180px; /* Ajusta según sea necesario */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; /* Agrega puntos suspensivos (...) al final del texto truncado */
        margin-bottom: -6px;
      }
}

@media (min-width: 1268px) {
    .compras {
        margin-left: -5rem;

    }

    .formulario-compra h2 {
        margin-left: 4rem;
    }

    .chakra-portal {
        z-index: 10000000;
        position: relative;
        margin-right: -2rem;
    }

    .chakra-toast {
        margin-right: 2rem;
    }


}

@media (max-width: 380px) {
    .cart-container {
        transform: scale(0.75);
        margin-bottom: -6.2rem;
        margin-right: 50px;
    }

    .cart {
        margin-bottom: 5rem;
        margin-left: 13px;
        margin-top: -5.8rem;
    }
    
}


